@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-blue {
        @apply border bg-blue-600 rounded-md text-white px-4 py-2 m-2 text-xs transition duration-500 hover:bg-blue-700 focus:outline-none 
    }
    .btn-blue-active {
        @apply transition duration-100 active:shadow-md active:bg-blue-800
    }
    .tooltip {
        @apply invisible absolute;
    }
    .has-tooltip:hover .tooltip {
        @apply visible z-50
    }
}